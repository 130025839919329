const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://vqchlls9r3.execute-api.us-west-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://jnozq8l0ca.execute-api.us-west-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.evoution.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.8.0',
    HOSTNAME: 'https://targets.evolution.forwoodsafety.com'
  }
};

export default config;